<template>
  <div class="dxby-home-container">
    <div class="dxby-home-header">
      <div class="dxby-home-header-wrap">
        <router-link to="/" class="logo">
          <img
            src="@/assets/icon/logo.png"
            alt="广东鸿仁大新布业有限公司"
          >
          <div class="logo-text">
            <span>大新布业</span>
            <i>DAXINTEXTILE</i>
          </div>
        </router-link>
      </div>
    </div>
    <div class="dxby-home-site">
      <ul>
        <li>当前位置 > <b>智能人事</b></li>
        <li>
          <div class="dxby-home-site-right">
            <span @click="() => $router.push({path: '/tableSetting'})">配置</span>
            <span @click="freshData">刷新</span>
            <span @click.stop="close = true">文件上传</span>
            <span @click="backFn">返回上一级</span>
          </div>
        </li>
      </ul>
    </div>
    <!-- 内容 S -->
    <router-view/>
    <!-- 内容 E -->
    <!-- 文件上传模块 S -->
    <div
      class="dxby-upload-wrap"
      v-if="close"
      @click.self="close = false"
    >
      <div class="dxby-upload-con">
        <div class="dxby-upload-con-wrap">
          <div class="dxby-upload-title">
            <h3>文件上传</h3>
            <img
              src="@/assets/icon/close.png"
              alt=""
              @click.stop="close = false"
              title="关闭"
            >
          </div>
          <div class="dxby-upload-time">
            <p>上传日期：</p>
            <el-date-picker
              v-model="value"
              type="date"
              placeholder="请输入日期"
              :disabledDate="disabledDate"
              value-format="YYYY-MM-DD"
              format="YYYY-MM-DD"
            ></el-date-picker>
          </div>
          <div class="dxby-upload-file">
            <p>excel上传：</p>
            <label
              for="excelFile"
            >
              <div class="excel-container">
                <svg
                  class="icon"
                  width="200"
                  height="200"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill="currentColor" d="M480 480V128a32 32 0 0164 0v352h352a32 32 0 110 64H544v352a32 32 0 11-64 0V544H128a32 32 0 010-64h352z"></path>
                </svg>
              </div>
              <input
                class="file"
                ref="upload"
                type="file"
                accept=".xls, .xlsx"
                @change="readExcel"
                id="excelFile"
              >
            </label>
            <div
              class="excel-file"
              v-if="excelName && excelName !== ''"
            >
              <img
                height="15"
                :src="del"
                alt=""
                class="delete"
                title="点击删除"
                @click="deleteExcel"
              >
              <img
                height="60"
                :src="xlsIcon"
                alt=""
              >
              <i>{{excelName}}</i>
            </div>
          </div>
          <div
            class="dxby-smt-wrap"
            @click="smtExcel"
          >
            <ElButton type="primary">提交</ElButton>
          </div>
        </div>
      </div>
    </div>
    <!-- 文件上传模块 E -->
  </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { xlsIcon, del } from '@/utils/iconManage.js'
import { requestApi } from '@/request'
import { uploadFileApi } from '@/request/apiSuffix'
import { ElDatePicker, ElButton } from 'element-plus'

export default {
  name: 'Home',
  setup () {
    // Data
    const _data = reactive({
      disabledDate (time) {
        return time.getTime() > Date.now() - 3600 * 1000 * 24 // 从今以后的时间
      },
      value: '',
      excelName: '',
      xlsIcon,
      del,
      file: {},
      close: false
    })
    let isFormData = ref(null)

    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    // 返回上一页
    const backFn = () => router.go(-1)
    // 点击刷新
    const freshData = async () => {
      let obj = JSON.stringify(route.query)
      if (obj === '{}') {
        store.dispatch('getData', '')
      } else {
        const { filePath } = route.query
        store.dispatch('getData', filePath)
      }
    }
    // 文件上传
    const readExcel = e => {
      let file = e.target.files[0]
      _data.excelName = file.name
      const fileFormData = new FormData()
      fileFormData.append('file', file)
      isFormData = fileFormData
      if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
        alert('上传格式不正确，请上传xls或者xlsx格式')
        return false
      }
    }
    // 删除
    const deleteExcel = () => {
      _data.excelName = ''
      isFormData = null
    }
    // 提交
    const smtExcel = async () => {
      if (_data.value === '') {
        alert('请填写上传日期')
        return false
      }
      if (isFormData.value === null) {
        alert('请上传文件')
        return false
      }
      const res = await requestApi('POST', isFormData, `${uploadFileApi}${_data.value}`, 'multipart/form-data')
      console.log(res)
      const { code, msg } = res.data
      if (code === 200) {
        _data.excelName = ''
        isFormData = null
        alert(msg)
      } else {
        alert(msg)
      }
    }

    return {
      ...toRefs(_data),
      backFn,
      freshData,
      readExcel,
      deleteExcel,
      smtExcel
    }
  },
  components: {
    ElDatePicker,
    ElButton
  }
}
</script>

<style scoped lang="less">
.otx (@num) {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @num;
  -webkit-box-orient: vertical;
}
.dxby-home-container {
  .dxby-home-header {
    height: 45px;
    background-color: #5b89fe;
    .dxby-home-header-wrap {
      width: 1200px;
      margin: 0 auto;
      .logo {
        display: flex;
        align-items: center;
        img {
          width: 36px;
          height: 36px;
        }
        .logo-text {
          display: block;
          span, i {
            color: #fff;
            padding-left: 6px;
            display: block;
          }
          span {
            font-size: 15px;
            padding-top: 3px;
            letter-spacing: 6px;
          }
          i {
            font-size: 12px;
            padding-top: 2px;
            letter-spacing: -.5px;
          }
        }
      }
    }
  }
  .dxby-home-site {
    width: 1200px;
    margin: 30px auto;
    border-bottom: 1px solid #eee;
    padding: 20px 0;
    font-size: 14px;
    color: #333;
    ul {
      display: flex;
      justify-content: space-between;
      align-items: cneter;
      li {
        b {
          color: #5b89fe;
        }
      }
      li {
        .dxby-home-site-right {
          display: flex;
          span {
            padding: 6px 12px;
            background-color: #5b89fe;
            border-radius: 3px;
            color: #fff;
            font-size: 12px;
            cursor: pointer;
            display: block;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
// 文件上传
.dxby-upload-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.5);
  .dxby-upload-con {
    width: 850px;
    margin: 90px auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 6px #ddd;
    .dxby-upload-con-wrap {
      padding: 10px;
      .dxby-upload-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eee;
        padding: 20px 0;
        h3 {
          display: block;
          font-size: 14px;
          color: #333;
        }
        img {
          width: 20px;
          cursor: pointer;
        }
      }
      .dxby-upload-time {
        width: 500px;
        margin: 100px auto 10px;
        display: flex;
        align-items: center;
      }
      .dxby-upload-file {
        width: 500px;
        margin: 50px auto 0;
        display: flex;
        align-items: center;
        label {
          width: 120px;
          height: 120px;
          border: 1px dashed #eee;
          display: block;
          position: relative;
          .excel-container {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            background-color: #fff;
            text-align: center;
            line-height: 145px;
            cursor: pointer;
            svg {
              height: 3em;
              width: 3em;
              color: #eee;
            }
          }
          .file {
            width: 50px;
            opacity: 0;
          }
        }
        .excel-file {
          width: 60px;
          margin-left: 30px;
          text-align: center;
          position: relative;
          img, i {
            display: block;
          }
          img {
            margin-bottom: 5px;
          }
          i {
            width: 100%;
            .otx(3);
            font-size: 14px;
          }
          .delete {
            position: absolute;
            top: 0;
            right: 0;
            margin-bottom: 0;
            z-index: 3;
            opacity: .5;
            cursor: pointer;
            display: none;
          }
        }
        .excel-file:hover {
         .delete {
           display: block;
         }
        }
      }
      .dxby-smt-wrap {
        width: 500px;
        margin: 0 auto;
        .el-button--primary {
          width: 120px;
          margin: 120px auto;
          display: block;
        }
      }
    }
  }
}
</style>
